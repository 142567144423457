import http from '@/api/request'

export function getHearder () {
  return http().get('/base/api/page/web/nav')
}

export function getFooter () {
  return http().get('/base/api/page/web/footer')
}

export function getComponentList () {
  return http().get('/base/api/page/web/list')
}

export function getComponentData (params) {
  return http().get('/base/api/page/web/detail', { params })
}

export function getSuspendData () {
  return http().get('/base/api/page/web/suspend')
}

/**
 * 轮播图播放视频
 * @param data
 * @returns {*}
 */
export function bannerVideoPreview(data) {
  return http().post('/resource/api/material/video/preview', data)
}
